<template>
    <div id="app">
        <router-view/>
    </div>
</template>
<script>
    export default {
        data() {
            return {
            }
        },
        methods: {
        },
        mounted() {
            // 禁止页面缩放
            window.onload = function () {
                document.addEventListener("touchstart", function (event) {
                    if (event.touches.length > 1) {
                    event.preventDefault();
                    }
                });
                document.addEventListener("gesturestart", function (event) {
                    event.preventDefault();
                });
            };
        }
    };
</script>

<style lang="less">
    html, body {
        margin: 0;
        padding: 0;
        background-color: #F1FBFB;
    }
</style>
