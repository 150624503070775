import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ViewUI from 'view-design';
import vant from 'vant';
import moment from 'moment';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {_isMobile} from './utils/utils'

const path = _isMobile() ? 'h5' : 'pc'
import (`./assets/css/${path}/style.less`)

Vue.use(ViewUI);
Vue.use(vant);
Vue.use(Loading);

Vue.config.productionTip = false;
Vue.prototype.$moment = moment;

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
