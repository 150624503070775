import Vue from 'vue'
import Store from '../store/index'
import VueRouter from 'vue-router'
import {_isMobile} from '../utils/utils'

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

// 如果是移动端，则路由到 h5 目录；否则路由到 pc 目录
const path = _isMobile() ? 'h5' : 'pc'

const routes = [
    {
        path: '/login',
        name: 'login',
        meta: {title: '登录'},
        component: resolve => { require([`../views/${path}/login_`], resolve) }
    },
    {
        path: '/register',
        name: 'register',
        meta: {title: '注册'},
        component: resolve => { require([`../views/${path}/register`], resolve) }
    },
    {
        path: '/project/:id',
        name: 'project',
        meta: {title: '首页'},
        component: resolve => { require([`../views/${path}/home`], resolve) }
    },
    {
        path: '/position/:id',
        name: 'position',
        meta: {title: '职位详情'},
        component: resolve => { require([`../views/${path}/jobDetail`], resolve) }
    },
    {
        path: '/application',
        name: 'application',
        meta: {title: '报名申请'},
        component: resolve => { require([`../views/${path}/application`], resolve) }
    },
    {
        path: '/submitQualification/:id',
        name: 'submitQualification',
        meta: {title: '提交资质'},
        component: resolve => { require([`../views/${path}/submitQualification`], resolve) }
    },
    {
        path: '/myApplication',
        name: 'myApplication',
        meta: {title: '我的报名'},
        component: resolve => { require([`../views/${path}/myApplication`], resolve) }
    },
    {
        path: '/messageCenter',
        name: 'messageCenter',
        meta: {title: '消息中心'},
        component: resolve => { require([`../views/${path}/messageCenter`], resolve) }
    },
    {
        path: '/helpCenter',
        name: 'helpCenter',
        meta: {title: '帮助中心'},
        component: resolve => { require([`../views/${path}/helpCenter`], resolve) }
    },
    {
        path: '/userCenter',
        name: 'userCenter',
        meta: {title: '用户中心'},
        component: resolve => { require([`../views/${path}/userCenter`], resolve) }
    },
    {
        path: '/editPassword',
        name: 'editPassword',
        meta: {title: '修改密码'},
        component: resolve => { require([`../views/${path}/editPassword`], resolve) }
    },
    {
        path: '/projectPrompt',
        name: 'projectPrompt',
        meta: {title: '项目提示'},
        component: resolve => { require([`../views/${path}/projectPrompt`], resolve) }
    },
    {
        path: '/positionPrompt',
        name: 'positionPrompt',
        meta: {title: '职位提示'},
        component: resolve => { require([`../views/${path}/positionPrompt`], resolve) }
    },
    {
        path: '/applicationPrompt',
        name: 'applicationPrompt',
        meta: {title: '职位提示'},
        component: resolve => { require([`../views/${path}/applicationPrompt`], resolve) }
    },
    {
        path: '/submitQualifPrompt',
        name: 'submitQualifPrompt',
        meta: {title: '提交资质提示'},
        component: resolve => { require([`../views/${path}/submitQualifPrompt`], resolve) }
    },
    {
        path: '/termOfService',
        name: 'termOfService',
        meta: {title: '服务条款'},
        component: resolve => { require([`../views/${path}/termOfService`], resolve) }
    },
    {
        path: '/privacyPolicy',
        name: 'privacyPolicy',
        meta: {title: '隐私政策'},
        component: resolve => { require([`../views/${path}/privacyPolicy`], resolve) }
    },
    {
        path: '/forgetPassword',
        name: 'forgetPassword',
        meta: {title: '忘记密码'},
        component: resolve => { require([`../views/${path}/forgetPassword`], resolve) }
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        Store.dispatch('changeTitle', to.meta.title).then(r => {})
    }
    next()
})
export default router
